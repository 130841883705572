import { Link, graphql } from "gatsby";
import React from "react";

import Layout from "../components/Layout";
import SEO from "../components/Seo";
import formatTimeToRead from "../utils/formatTimeToRead";

export default ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => (
  <Layout>
    <SEO lang="en" title="Home" />
    <section>
      {edges.map(({ node }) => {
        return (
          <Link className="dib no-underline w-100" to={node.fields.slug}>
            <article className="pv4 bt bb b--black-10 ph3 ph0-l">
              <div className="w-100 pr3-ns">
                <h1 className="mt0 f3 lh-title">{node.frontmatter.title}</h1>
                <p className="lh-copy black">{node.frontmatter.excerpt}</p>
              </div>
              <p className="f6 lh-copy gray mv0">
                <time className="ttu">{node.frontmatter.date}</time>
                <span className="mh1"> • </span>
                <span className="f6 gray">
                  {formatTimeToRead(node.timeToRead, node.frontmatter.lang)}
                </span>
              </p>
            </article>
          </Link>
        );
      })}
    </section>
  </Layout>
);

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }, limit: 1000) {
      edges {
        node {
          id
          timeToRead
          frontmatter {
            title
            date(formatString: "DD/MM/YYYY")
            lang
            excerpt
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
